jQuery(document).ready(function ($) {
    $(function () {
        var speed = 0.3;
        var viewporth = $(window).height();
        var st = $(window).scrollTop();

        var parallaxImages = [];
        function prlxUpdateValues() {
            parallaxImages = [];
            $('.parallax').each(function (index) {
                var parallaxImage = {};
                parallaxImage.element = $(this);
                parallaxImage.offset = parallaxImage.element.offset().top;
                parallaxImages.push(parallaxImage);
            });
            prlxDraw();
        }
        function prlxDraw() {
            $.each(parallaxImages, function (index, parallaxImage) {
                parallaxImage.element.css('object-position', 'center ' + ((st) * speed) + 'px');
            });
        }

        $(window).on('resize', function () {
            prlxUpdateValues();
        });

        prlxUpdateValues();
        $(window).on('scroll', function () {
            st = $(window).scrollTop();
            prlxDraw();
        });
    });
});