/* --------------------------------------------------------------------------*/
/*                              FAQ Rich Snippets                            */
/* --------------------------------------------------------------------------*/

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("vangend-faq__item").length >= 1) {
    var items = document.getElementsByClassName("vangend-faq__item");
    var answers = document.getElementsByClassName("vangend-faq__item--answer");

    function setClass(els, className, fnName) {
      for (var i = 0; i < els.length; i++) {
        els[i].classList[fnName](className);
      }
    }

    for (var i = 0; i < items.length; i++) {
      items[i].addEventListener("click", function (e) {
        var setClasses = !this.classList.contains("vangend-faq__item--active");
        setClass(items, "vangend-faq__item--active", "remove");

        // Set max-height of all answers to null
        for (var j = 0; j < answers.length; j++) {
          answers[j].style.maxHeight = null;
        }

        if (setClasses) {
          this.classList.toggle("vangend-faq__item--active");
          var answer = this.querySelector('.vangend-faq__item--answer');
          answer.style.maxHeight = answer.scrollHeight + 'px';
        }
      });
    }
  }
});