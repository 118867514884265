document.addEventListener("DOMContentLoaded", function () {
  var openTrigger = document.getElementById("staging-notice-modal-trigger");
  var closeTrigger = document.getElementById("close-staging-notice-modal");
  var modal = document.querySelector(".staging-notice-modal");
  var modalContent = document.querySelector(".staging-notice-modal__content");

  if (openTrigger && modal) {
    openTrigger.addEventListener("click", function (event) {
      event.preventDefault(); // Prevent default action if it's an anchor tag
      modal.classList.add("active");
    });
  }

  if (closeTrigger && modal) {
    closeTrigger.addEventListener("click", function (event) {
      event.preventDefault(); // Prevent default action if it's an anchor tag
      modal.classList.remove("active");
    });
  }

  if (modal && modalContent) {
    modal.addEventListener("click", function (event) {
      if (!modalContent.contains(event.target as Node)) {
        modal.classList.remove("active");
      }
    });
  }
});
